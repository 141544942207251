import BaseSwrRequest from "../../interfaces/base-swr-request";
import useFetch from "../shared/useFetch";
import { useMutation } from "../shared/useMutation";
import { DTO_BACKOFFICE } from "../../types";

interface UseLazyExtendedLevelDetailProps {
  level?: number;
}
const useLazyExtendedLevelDetail = () => {
  const mutation = useMutation<
    { query: UseLazyExtendedLevelDetailProps },
    DTO_BACKOFFICE["LevelExtendedDtoPaginatedList"]
  >({
    baseUrl: process.env.NEXT_PUBLIC_API_URL_BACKOFFICE,
    url: "/Operator/get-levels-detail",
    method: "GET",
  });

  return mutation;
};

export default useLazyExtendedLevelDetail;
