import { DTO_BACKOFFICE } from "../../types";
import useFetch from "../shared/useFetch";

const useLevelsDetail = () => {
  const { data, isValidating } = useFetch<DTO_BACKOFFICE["LevelExtendedDtoPaginatedList"]>({
    baseUrl: process.env.NEXT_PUBLIC_API_URL_BACKOFFICE,
    url: "/Operator/get-levels-detail",
    queryParams: new URLSearchParams({
      PageNumber: "1",
      PageSize: "100",
    }),
  });

  return {
    data,
    isValidating,
  };
};

export default useLevelsDetail;
