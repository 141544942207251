import { Typography as MUITypography, Tooltip, TypographyProps } from "@mui/material";
import { styled } from "@mui/material/styles";
import { theme } from "../../theme";
const Title = styled(MUITypography)<TypographyProps>(() => ({
  fontFamily: "Nunito, sans-serif",
}));

interface ParagraphProps extends TypographyProps {
  bold?: boolean;
  highlight?: boolean;
  maxLines?: number;
}
const Paragraph = styled(MUITypography)<ParagraphProps>(({ bold, highlight, color, maxLines, ...rest }) => ({
  fontFamily: "Nunito, sans-serif",
  fontSize: "0.75rem",
  lineHeight: "1rem",
  color: highlight ? theme.customColors.yellow : color?.toString() ?? "#fff",
  fontWeight: bold ? 700 : 400,
  ...(maxLines && {
    display: "-webkit-box",
    WebkitLineClamp: maxLines,
    WebkitBoxOrient: "vertical",
    overflow: "hidden",
    textOverflow: "ellipsis",
  }),
  ...(rest as any),
}));

interface TooltipParagraphProps extends ParagraphProps {
  title: string;
}
const TooltipParagraph: React.FC<TooltipParagraphProps> = ({ title, ...rest }) => {
  return (
    <Tooltip title={title}>
      <Paragraph {...rest} />
    </Tooltip>
  );
};

const CollapseTitle = styled(MUITypography)<TypographyProps>(() => ({
  fontFamily: "Nunito, sans-serif",
  fontSize: "0.875rem",
  lineHeight: "1.1875rem",
}));

export { Title, Paragraph, CollapseTitle, TooltipParagraph };
