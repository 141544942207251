const addOrdinalSuffix = (num: number) => {
  if (num === 0) {
    return "0th";
  }
  const suffixes = ["th", "st", "nd", "rd"];
  const v = num % 100;
  return num + (suffixes[(v - 20) % 10] || suffixes[v] || suffixes[0]);
};

export { addOrdinalSuffix };
