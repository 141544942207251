import * as React from "react";

const ArrowRightSvg = ({ color = "white" }: any) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M8.5119 19.5694C8.19741 19.2999 8.16099 18.8264 8.43056 18.5119L14.0122 12L8.43056 5.48809C8.16099 5.1736 8.19741 4.70012 8.51191 4.43056C8.8264 4.16099 9.29987 4.19741 9.56944 4.5119L15.5694 11.5119C15.8102 11.7928 15.8102 12.2072 15.5694 12.4881L9.56944 18.4881C9.29987 18.8026 8.8264 18.839 8.5119 18.5694L8.5119 19.5694Z"
      fill={color}
    />
  </svg>
);

export default ArrowRightSvg;
