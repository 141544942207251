import React from "react";

import { Box, useMediaQuery, useTheme } from "@mui/material";
import { Paragraph, TooltipParagraph } from "../typography";

interface LevelItemProps {
  title?: React.ReactNode;
  description?: React.ReactNode;
  imageUrl?: string;
  footerItems?: {
    label: string;
    value?: React.ReactNode;
    colored?: boolean;
  }[];
}

const LevelItem: React.FC<LevelItemProps> = ({ title, description, footerItems, imageUrl }) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("md"));

  const size = matches ? 240 : 200;

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "0.5rem",
        marginBottom: "0.5rem",
      }}>
      {imageUrl && (
        <Box justifyContent="center" display="flex" position="relative">
          <img src={imageUrl ?? ""} alt={""} width={size} height={size} />
        </Box>
      )}
      <Box alignItems="center" display="flex" flexDirection="column">
        {typeof title === "string" ? (
          <Paragraph title={title ?? ""} fontSize={"1rem"} textAlign={"center"} maxLines={1}>
            {title ?? ""}
          </Paragraph>
        ) : (
          title
        )}
        {description && (
          <Box sx={{ display: "flex", flexDirection: "row", gap: "0.5rem" }}>
            {typeof description === "string" ? (
              <Paragraph highlight textAlign="center">
                {description}
              </Paragraph>
            ) : (
              description
            )}
          </Box>
        )}
        {footerItems && (
          <Box sx={{ display: "flex", flexDirection: "column", gap: "0.5rem", width: "100%", marginTop: "1rem" }}>
            {footerItems.map((item, index) => (
              <Box
                key={index}
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                  width: "100%",
                  borderBottomWidth: "1px",
                  borderBottomStyle: "solid",
                  borderBottomColor: "#303030",
                  gap: "0.2rem",
                }}>
                <Paragraph
                  fontSize={"1rem"}
                  textAlign={"center"}
                  // maxLines={1}
                  lineHeight={"1.5rem"}
                  sx={{ textWrap: "nowrap" }}
                  highlight={item?.colored}
                  color={item?.colored ? undefined : "#5F5F5F"}>
                  {item.label}
                </Paragraph>
                <TooltipParagraph
                  fontSize={"0.75rem"}
                  lineHeight={"1.5rem"}
                  textAlign={"center"}
                  maxLines={1}
                  title={item.value?.toString() ?? ""}
                  color={item?.colored ? undefined : "#5F5F5F"}>
                  {item.value}
                </TooltipParagraph>
              </Box>
            ))}
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default LevelItem;
