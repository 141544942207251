import { Avatar, Box, Card, NoSsr } from "@mui/material";
import useFetch from "../../hooks/shared/useFetch";
import { DTO, DTO_BACKOFFICE } from "../../types";
import { useLoader } from "../../utils/hooks/loader/use-loader";
import { useRouter } from "next/router";
import { Paragraph, Title } from "../../components/typography";
import { BorderLinearProgress } from "../../components/commons/progress-bar";
import CollapseSection from "../../components/commons/collapsable-section";
import React, { useEffect, useMemo } from "react";
import { getLevelProgress } from "../../utils/functions/get-level-progress";
import { addOrdinalSuffix } from "../../utils/functions/add-ordinal-prefix";
import { useTranslation } from "react-i18next";
import { lowerCaseFirstLetter } from "../../utils/translations/typography";
import useLevelsDetail from "../../hooks/levels/useLevelsDetail";
import useLazyLevelDetail from "../../hooks/levels/useLazyExtendedLevelDetail";
import LevelItem from "../../components/level-item";
import useLazyPlayerDetail from "@/hooks/levels/useLazyPlayerDetail";

const fallbackUrl = "/placeholderProfileImage.png";

const LevelsPage = () => {
  const router = useRouter();
  const { id, lang } = router.query;
  const { t } = useTranslation();

  const { data: levelsDetails, isValidating: isLoadingLevelsDetails } = useLevelsDetail();
  const { data: player, isMutating: isLoadingPLayerDetail, error, mutate: getPlayerDetail } = useLazyPlayerDetail();

  const { data: level, isMutating: isLoadingLevel, mutate: getLevel } = useLazyLevelDetail();
  const { data: previousLevel, isMutating: isLoadingPreviousLevel, mutate: getPreviousLevel } = useLazyLevelDetail();

  const sortedByLevel = useMemo(() => {
    const sorted = [...(levelsDetails?.items ?? [])]?.sort((a, b) => (a?.level ?? 0) - (b?.level ?? 0));
    return sorted;
  }, [levelsDetails]);

  useEffect(() => {
    if (id) {
      getPlayerDetail({ query: { customerPlayerId: id as string } });
    }
  }, [id]);

  useEffect(() => {
    if (player?.level !== undefined) {
      getLevel({ query: { level: player?.level } });
      if (player?.level > 0) {
        getPreviousLevel({ query: { level: player?.level - 1 } });
      }
    }
  }, [player?.level]);

  const progress = useMemo(() => {
    if (!player || !level) return 0;
    const currentLevelDetail = level?.items?.[0];
    const previousLevelDetail = previousLevel?.items?.[0];

    const calculatedProgress = getLevelProgress(player?.points ?? 0, currentLevelDetail, previousLevelDetail);
    const minMax0To100 = Math.min(Math.max(calculatedProgress, 0), 100);
    return minMax0To100;
  }, [player, level, previousLevel]);

  const showErrorMessage = (error || !id) && !isLoadingPLayerDetail;

  const seeAllSectionUrl = useMemo(() => {
    if (id) {
      return `/all-levels?id=${id}&lang=${lang}`;
    } else {
      return `/all-levels?lang=${lang}`;
    }
  }, [id, lang]);
  useLoader({
    trackLoadingValue: isLoadingPLayerDetail || isLoadingLevel || isLoadingPreviousLevel || isLoadingLevelsDetails,
  });

  return (
    <NoSsr>
      <Card
        sx={{
          px: { sm: "40px", xs: "15px" },
          py: { sm: "20px", xs: "10px" },
          width: "100%",
          display: "flex",
          flexDirection: "column",
          gap: "10px",
          margin: 0,
          backgroundColor: "#1F1F1F",
        }}>
        {showErrorMessage && (
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <Title color="red">
              {t("playerDetail.toReachNewLevelYouNeedToBeLoggedIn", {
                eur: "1",
              })}
              .
            </Title>
          </Box>
        )}
        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          <Paragraph highlight>
            {`* ${t("playerDetail.conversionBetweenPointsAndEurWagered", {
              currency: "EUR",
              value: "1",
              points: "1000",
            })}`}
          </Paragraph>
        </Box>
        <Box sx={{ display: "flex", gap: { sm: "36px", xs: "20px" }, alignItems: "center" }}>
          <Box sx={{ display: "flex", gap: "6px", flexDirection: "column", alignItems: "center" }}>
            <Avatar
              alt={player?.userName ?? ""}
              src={fallbackUrl}
              sx={{ width: { sm: "80px", xs: "60px" }, height: { sm: "80px", xs: "60px" } }}
            />
            <Paragraph highlight bold sx={{ display: { sm: "none", xs: "block" } }}>
              {addOrdinalSuffix(player?.level ?? 0)} {lowerCaseFirstLetter(t("appShared.level"))}
            </Paragraph>
          </Box>
          <Box>
            <Box sx={{ display: "flex", gap: { sm: "20px", xs: "10px" } }}>
              <Paragraph highlight bold sx={{ display: { sm: "block", xs: "none" } }}>
                {addOrdinalSuffix(player?.level ?? 0)} {lowerCaseFirstLetter(t("appShared.level"))}
              </Paragraph>
              <Paragraph>
                {player?.points ?? 0} {lowerCaseFirstLetter(t("appShared.points"))}
              </Paragraph>
            </Box>
          </Box>
        </Box>
        <Box sx={{ display: "flex", gap: "20px", flexDirection: "column" }}>
          <Title variant="h4" color="white">
            {t("playerDetail.progressIndicator")}
          </Title>
          <Box sx={{ display: "flex", gap: "8px", flexDirection: "column" }}>
            <Box sx={{ mx: { sm: "10px", xs: 0 }, display: "flex", justifyContent: "space-between" }}>
              <Paragraph highlight bold>
                {addOrdinalSuffix(player?.level ?? 0)} {lowerCaseFirstLetter(t("appShared.level"))}
              </Paragraph>
              <Paragraph bold color="#969696">
                {level?.items?.[0]?.isLatest
                  ? t("appShared.maxPoints")
                  : addOrdinalSuffix((player?.level ?? 0) + 1) + " " + lowerCaseFirstLetter(t("appShared.level"))}
              </Paragraph>
            </Box>
            <BorderLinearProgress variant="determinate" value={progress} />
            <Box sx={{ display: "flex", justifyContent: "center", position: "relative" }}>
              <Box>
                <Title variant="h4">{progress} %</Title>
              </Box>
            </Box>
          </Box>
        </Box>
        <CollapseSection<DTO_BACKOFFICE["LevelExtendedDto"]>
          label={t("playerDetail.allEliteLevels")}
          items={sortedByLevel ?? []}
          carouselStyle={{ gap: "3rem" }}
          seeAllAction={() => router.push(seeAllSectionUrl)}
          renderItem={level => {
            const title = `Elite level ${level?.isLatest == true ? "Leet King" : (level?.level as number) + 1}`;
            return (
              <Box sx={{ width: { xs: "240px" } }}>
                <LevelItem
                  title={title}
                  description={`${level?.threshold} EXP`}
                  imageUrl={`${process.env.NEXT_PUBLIC_CDN_URL}/levels/${(level?.level as number) + 1}.webp`}
                  footerItems={[
                    {
                      label: "Cashback",
                      value: level?.cashbackReward ? `${level?.cashbackReward?.percentage ?? "-"}%` : "-",
                      colored: !!level?.cashbackReward,
                    },
                    {
                      label: "Free spins",
                      value: level?.freeSpinsReward
                        ? `${level?.freeSpinsReward?.gameName} ${level?.freeSpinsReward?.betCount}*${level?.freeSpinsReward?.realBetValue} ${level?.freeSpinsReward?.currency}`
                        : "-",
                      colored: !!level?.freeSpinsReward,
                    },
                    {
                      label: "Deposit Top Up",
                      value: level?.depositTopUpReward ? `${level?.depositTopUpReward?.percentage ?? "-"}%` : "-",
                      colored: !!level?.depositTopUpReward,
                    },
                    {
                      label: "Free Cash",
                      value: level?.freeCashReward
                        ? `${level?.freeCashReward?.amount ?? "-"} ${level?.freeCashReward?.currency}`
                        : "-",
                      colored: !!level?.freeCashReward,
                    },
                  ]}
                />
              </Box>
            );
          }}></CollapseSection>
      </Card>
    </NoSsr>
  );
};

export default LevelsPage;
