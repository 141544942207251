import { useRouter } from "next/router";
import useSWR from "swr";
import { useAppContext } from "../../context/useAppContext";
import { HttpMethods } from "../../interfaces/update-module";
import { swrSingleGetConfig } from "../../services/swr";
import { debounce, merge } from "lodash";
import { pathsUrls } from "../../types";

type FetchProps = {
  url: pathsUrls | Omit<string, pathsUrls>;
  queryParams?: URLSearchParams;
  swrConfig?: Partial<typeof swrSingleGetConfig>;
  baseUrl?: string;
};

const useFetch = <T>({ url, queryParams, swrConfig, baseUrl }: FetchProps) => {
  const context = useAppContext();
  const router = useRouter();

  const fetcher = (url: string) =>
    fetch(url, {
      method: HttpMethods.Get,
      headers: {
        "Content-Type": "application/json",
        "X-Api-Key": context?.apiKey || "",
      },
    }).then(res => {
      if (!res.ok) {
        return res.json().then(data => {
          throw new Error(data.message);
        });
      }
      return res.json();
    });

  const { data, error, isValidating, mutate } = useSWR<T>(
    `${baseUrl ?? process.env.NEXT_PUBLIC_API_URL}${url}${queryParams ? `?${queryParams}` : ""}`,
    fetcher,
    merge(swrSingleGetConfig, swrConfig),
  );

  const debouncedSearch = debounce(async () => {
    mutate();
  }, 1000);

  return { data, error, isValidating, mutate, router, context, debouncedSearch };
};

export default useFetch;
