import * as React from "react";

const ArrowLeftSvg = ({ color = "white" }: any) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M15.4881 19.5694C15.8026 19.2999 15.839 18.8264 15.5694 18.5119L9.98781 12L15.5694 5.48809C15.839 5.1736 15.8026 4.70012 15.4881 4.43056C15.1736 4.16099 14.7001 4.19741 14.4306 4.51191L8.43056 11.5119C8.18978 11.7928 8.18978 12.2072 8.43056 12.4881L14.4306 18.4881C14.7001 18.8026 15.1736 18.839 15.4881 18.5694L15.4881 19.5694Z"
      fill={color}
    />
  </svg>
);

export default ArrowLeftSvg;
