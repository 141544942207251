import { useMutation } from "../shared/useMutation";
import { DTO } from "../../types";

interface UseLazyPlayerDetailProps {
  customerPlayerId: string;
}
const useLazyPlayerDetail = () => {
  const mutation = useMutation<{ query: UseLazyPlayerDetailProps }, DTO["PlayerDto"]>({
    url: "/Gamification/player",
    method: "GET",
  });

  return mutation;
};

export default useLazyPlayerDetail;
