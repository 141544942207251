import { Box, Button, Collapse, IconButton } from "@mui/material";
import { useRef, useState } from "react";
import ArrowRight from "../../assets/ArrowRight";
import AllowLeft from "../../assets/AllowLeft";
import { CollapseTitle, Title } from "../typography";
import { useTranslation } from "react-i18next";
interface CollapseSectionProps<T> {
  label?: React.ReactNode;
  settings?: {
    showHeader?: boolean;
    showActions?: boolean;
    defaultOpen?: boolean;
  };
  items: T[];
  carouselStyle?: React.CSSProperties;
  seeAllAction?: () => void;
  renderItem: (item: T, index: number) => React.ReactNode;
}
const CollapseSection = <T,>({
  label,
  items,
  renderItem,
  settings,
  carouselStyle,
  seeAllAction,
}: CollapseSectionProps<T>) => {
  const { showActions = true, defaultOpen = false, showHeader = true } = settings ?? {};
  const [collapseOpen, setCollapseOpen] = useState(defaultOpen);
  const { t } = useTranslation();

  const scrollRef = useRef<HTMLDivElement>(null);
  const handleScroll = (scrollOffset: number) => {
    if (scrollRef.current && !collapseOpen) {
      scrollRef.current.scrollLeft += scrollOffset;
    }
  };

  return (
    <Box sx={{ display: "flex", gap: "20px", flexDirection: "column" }}>
      {showHeader && (
        <Box width="100%" justifyContent="space-between" display="flex" alignItems="center" my="2">
          <Title variant="h4" color="white">
            {label}
          </Title>
          {showActions && (
            <Box sx={{ display: "flex", gap: "5px", flexDirection: "row" }}>
              <Button
                disableRipple
                style={{
                  width: "100%",
                  margin: 0,
                  padding: 0,
                  alignItems: "center",
                  display: "flex",
                  justifyContent: "center",
                }}
                color={"secondary"}
                variant={"text"}
                onClick={() => (seeAllAction ? seeAllAction() : setCollapseOpen(!collapseOpen))}>
                <CollapseTitle>{collapseOpen ? t("appShared.seeLess") : t("appShared.seeAll")}</CollapseTitle>
              </Button>
              <Box sx={{ display: "flex", flexDirection: "row" }}>
                <IconButton disabled={collapseOpen} onClick={() => handleScroll(-150)}>
                  <AllowLeft color={"white"} />
                </IconButton>
                <IconButton disabled={collapseOpen} onClick={() => handleScroll(150)}>
                  <ArrowRight color={"white"} />
                </IconButton>
              </Box>
            </Box>
          )}
        </Box>
      )}
      <Collapse in={!collapseOpen} timeout={0} unmountOnExit style={{ width: "100%" }}>
        <div
          ref={scrollRef}
          style={{
            display: "flex",
            overflowX: "auto",
            scrollBehavior: "smooth",
            WebkitOverflowScrolling: "touch",
            scrollSnapType: "x mandatory",
            scrollPadding: "0 10px",
            width: "100%",
            gap: "0.75rem",
            ...carouselStyle,
          }}>
          {items.map((item, index) => (
            <div key={index} style={{ scrollSnapAlign: "center", minWidth: "fit-content" }}>
              {renderItem(item, index)}
            </div>
          ))}
        </div>
        <div style={{ marginLeft: "10px" }}></div>
      </Collapse>
      <Collapse in={collapseOpen} timeout={0} unmountOnExit style={{ width: "100%" }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            gap: "0.75rem",
            justifyContent: { xs: "space-around", sm: "space-between" },
          }}>
          {items.map((item, index) => (
            <Box key={index} sx={{ minWidth: "fit-content" }}>
              {renderItem(item, index)}
            </Box>
          ))}
        </Box>
      </Collapse>
    </Box>
  );
};

export default CollapseSection;
