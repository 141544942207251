import { useCallback, useEffect } from "react";
import { useLoaderContext } from "./loader-context";

interface UseLoaderProps {
  trackLoadingValue: boolean;
}
const useLoader = ({ trackLoadingValue }: UseLoaderProps) => {
  const { loading, setLoading } = useLoaderContext();

  const start = useCallback(() => {
    const loadingCount = loading + 1;
    setLoading(loadingCount);
  }, [loading, setLoading]);

  const stop = useCallback(() => {
    const loadingCount = loading > 0 ? loading - 1 : 0;
    setLoading(loadingCount);
  }, [loading, setLoading]);

  useEffect(() => {
    if (typeof trackLoadingValue === "boolean") {
      if (trackLoadingValue) {
        start();
      } else {
        stop();
      }
    }
    return () => {
      stop();
    };
  }, [trackLoadingValue]);

  return { start, stop };
};

export { useLoader };
