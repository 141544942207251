import { DTO } from "../../types";

const getLevelProgress = (xp: number, currentLevel?: DTO["LevelDto"], previousLevel?: DTO["LevelDto"]): number => {
  if (!currentLevel) return 0;
  let MIN = 0;
  let MAX = currentLevel?.threshold ?? 1;
  if ((currentLevel.level ?? 0) > 0) {
    MIN = previousLevel?.threshold ?? 1;
    MAX = currentLevel?.threshold ?? 2;
  }

  if (xp > MAX) return 100;

  const normalise = (value: number) => ((value - MIN) * 100) / (MAX - MIN);

  return Math.round(normalise(xp));
};

export { getLevelProgress };
