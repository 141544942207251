export type CreateModulProps = {
  type: UpdateModulType;
  subheader: string;
  title: string;
};

export type UseCreateModulProps = {
  method: HttpMethods;
};

export type UpdateModulValues = {
  version: string;
  osType: string;
  id: string;
  isLatest: string;
  environment: string;
};

export enum UpdateModulType {
  Game = "game",
  Launcher = "launcher",
  GameLatest = "game/latest",
  LauncherLatest = "launcher/latest",
}

export enum HttpMethods {
  Get = "GET",
  Put = "PUT",
  Post = "POST",
  Delete = "DELETE",
}
