import useSWRMutation, { SWRMutationConfiguration } from "swr/mutation";
import { useGlobalNotification } from "../../components/notifications/notification-provider";
import { useAppContext } from "../../context/useAppContext";
import { pathsUrls } from "../../types";
import cleanUrlParams from "@/utils/functions/clean-url-params";

export type SWRMutationConfig<Request = any, Response = any, Error = any> = SWRMutationConfiguration<
  Response,
  Error,
  Request,
  string
>;

export const useMutation = <Request extends { body?: any; query?: any } | undefined, Response = any, Error = any>({
  url,
  method,
  onSuccess,
  baseUrl,
}: {
  url: pathsUrls | Omit<string, pathsUrls>;
  baseUrl?: string;
  method: "POST" | "PUT" | "DELETE" | "GET";
  onSuccess?: (data: Response) => void;
}) => {
  const { showNotification } = useGlobalNotification();
  const context = useAppContext();
  const { trigger, data, isMutating, error, reset } = useSWRMutation<
    Response,
    Error,
    // assume all key is url, so it will be string type
    string,
    Request
  >(
    url as string,
    async (url: string, options: { arg: Request }) => {
      const response = await fetch(
        `${baseUrl ?? process.env.NEXT_PUBLIC_API_URL}${url}${
          options?.arg?.query ? `?${cleanUrlParams(new URLSearchParams(options?.arg?.query)).toString()}` : ""
        }`,
        {
          method,
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "X-Api-Key": context?.apiKey || "",
          },
          body: options.arg?.body ? JSON.stringify(options.arg?.body) : undefined,
        },
      );
      if (!response.ok) {
        const error = await response.json();
        showNotification(error?.message ?? "Server error", "error");
        return null;
      }
      if (response.status === 204) return null;
      return response?.json();
    },
    {
      onSuccess: onSuccess,
    },
  );

  return { mutate: trigger, data, isMutating, error, reset };
};
